
const mainCognitoConfig = {
    awsRegion: "us-east-1",
    awsUserPoolId: "us-east-1_0qUPePrZW",
    awsUserPoolWebClientId: "jcg1vfcivm5meanpdp02ds7nc"
};

const testCognitoConfig = {
    awsRegion: "us-east-1",
    awsUserPoolId: "us-east-1_egxCXGxjM",
    awsUserPoolWebClientId: "4o2ja9atd4guir5j2k2qb8ihla"
};

export const cognitoConfig = process.env.REACT_APP_ENV === "main" ? mainCognitoConfig : testCognitoConfig;

export const bifrostAPIUrl = window?.location?.href && window.location.href.includes("app.dop.omnitracs.com")
    ? "https://bifrost.dop.omnitracs.com/v1"
    : process.env.REACT_APP_ENV === "test"
        ? "https://test-bifrost.mip.baxtersoftware.com/v1"
        : "https://bifrost.mip.baxtersoftware.com/v1";

export function checkConditions (conditions, formState) {
    if (!conditions) {
        return true;
    }
    for (const condition of conditions) {
        if (!checkCondition(condition, formState)) {
            return false;
        }
    }
    return true;
}

const nullOrEmpty = (value) => (
    Array.isArray(value)
        ? value.length === 0
        : value === null || value === undefined || value === ""
);

export function checkCondition (condition, formState) {
    if (!condition) {
        return true;
    } else if (!formState.hasOwnProperty(condition.ParameterIdentifier)) {
        // the field being referenced doesn't exist, so treat it as false so it never shows
        // up and the form designer has to wonder why.
        const errorMessage = `A condition references form field '${  condition.ParameterIdentifier  }' that is not in the form state.`;
        alert(errorMessage);
        console.error(errorMessage);
        return false;
    } else if (condition.MatchType === "Contains") {
        return formState[condition.ParameterIdentifier].value.includes(condition.ComparisonValueAsString);
    } else if (condition.MatchType === "EndsWith") {
        return formState[condition.ParameterIdentifier].value.endsWith(condition.ComparisonValueAsString);
    } else if (condition.MatchType === "EqualTo") {
        let formValueAsString = "";
        const parentConditionMet = checkConditions(formState[condition.ParameterIdentifier].formConditions, formState) && checkConditions(formState[condition.ParameterIdentifier].field.Conditions, formState);
        if (!parentConditionMet) {
            // the field we depend on is not visible
            if (formState[condition.ParameterIdentifier].fieldType === "CheckboxField") {
                formValueAsString = "False";
            } else if (["TextField", "SelectField"].includes(formState[condition.ParameterIdentifier].fieldType)) {
                formValueAsString = "";
            }
        } else if (formState.hasOwnProperty(condition.ParameterIdentifier)) {
            formValueAsString = formState[condition.ParameterIdentifier].value;
        }
        if (formValueAsString === true) {
            formValueAsString = "True";
        } else if (formValueAsString === false) {
            formValueAsString = "False";
        }
        return formValueAsString === condition.ComparisonValueAsString || (formValueAsString === "" && condition.ComparisonValueAsString === "False");
    } else if (condition.MatchType === "NotEqualTo") {
        let formValueAsString = "";
        const parentConditionMet = checkConditions(formState[condition.ParameterIdentifier].formConditions, formState) && checkConditions(formState[condition.ParameterIdentifier].field.Conditions, formState);
        if (!parentConditionMet) {
            // the field we depend on is not visible
            if (formState[condition.ParameterIdentifier].fieldType === "CheckboxField") {
                formValueAsString = "False";
            } else if (["TextField", "SelectField"].includes(formState[condition.ParameterIdentifier].fieldType)) {
                formValueAsString = "";
            }
        } else if (formState.hasOwnProperty(condition.ParameterIdentifier)) {
            formValueAsString = formState[condition.ParameterIdentifier].value;
        }
        if (formValueAsString === true) {
            formValueAsString = "True";
        } else if (formValueAsString === false) {
            formValueAsString = "False";
        }
        return formValueAsString !== condition.ComparisonValueAsString;
    } else if (condition.MatchType === "NullOrEmpty") {
        return nullOrEmpty(formState[condition.ParameterIdentifier].value);
    } else if (condition.MatchType === "NotNullOrEmpty") {
        return !nullOrEmpty(formState[condition.ParameterIdentifier].value);
    } else if (condition.MatchType === "NumericGreaterThan") {
        return Number(formState[condition.ParameterIdentifier].value) > condition.ComparisonValue;
    } else if (condition.MatchType === "NumericGreaterThanOrEqualTo") {
        return Number(formState[condition.ParameterIdentifier].value) >= condition.ComparisonValue;
    } else if (condition.MatchType === "NumericLessThan") {
        return Number(formState[condition.ParameterIdentifier].value) < condition.ComparisonValue;
    } else if (condition.MatchType === "NumericLessThanOrEqualTo") {
        return Number(formState[condition.ParameterIdentifier].value) <= condition.ComparisonValue;
    } else if (condition.MatchType === "Regex") {
        let valueToMatch = formState[condition.ParameterIdentifier].value;
        const parentConditionMet = checkConditions(formState[condition.ParameterIdentifier].formConditions, formState) && checkConditions(formState[condition.ParameterIdentifier].field.Conditions, formState);
        if (!parentConditionMet) {
            valueToMatch = "";
        }
        if (!valueToMatch) {
            return false;
        }
        return valueToMatch.match(new RegExp(condition.ComparisonValueAsString));
    } else if (condition.MatchType === "StartsWith") {
        return formState[condition.ParameterIdentifier].value.startsWith(condition.ComparisonValueAsString);
    } else if (condition.MatchType === "In") {
        // assumes condition.ComparisonValue is an array and formState[id].value is a single value
        return condition.ComparisonValue && condition.ComparisonValue.hasOwnProperty("$values") && Array.isArray(condition.ComparisonValue.$values) && condition.ComparisonValue.$values.includes(formState[condition.ParameterIdentifier].value);
    } else if (condition.MatchType === "NotIn") {
        // opposite of In
        return condition.ComparisonValue && condition.ComparisonValue.hasOwnProperty("$values") && Array.isArray(condition.ComparisonValue.$values) && !condition.ComparisonValue.$values.includes(formState[condition.ParameterIdentifier].value);
    } else if (condition.MatchType === "Subset") {
        // - both condition.ComparisonValue and formState[id].value are sets
        // - all values must be present in the ComparisonValue
        if (condition.ComparisonValue && condition.ComparisonValue.hasOwnProperty("$values") && Array.isArray(condition.ComparisonValue.$values) && Array.isArray(formState[condition.ParameterIdentifier].value)) {
            for (const value of formState[condition.ParameterIdentifier].value) {
                if (!condition.ComparisonValue.$values.includes(value)) {
                    // if any value is NOT present, it's not a complete
                    // subset
                    return false;
                }
            }
            // if we made it through all values without returning false for
            // a missing one, then all were present.
            return true;
        }
        // value wasn't set or both condition and formstate were not arrays
        return false;
    } else if (condition.MatchType === "Disjoint") {
        // - both condition.ComparisonValue and formState[id].value are sets
        // - NO values must be present in the ComparisonValue
        if (condition.ComparisonValue && condition.ComparisonValue.hasOwnProperty("$values") && Array.isArray(condition.ComparisonValue.$values) && Array.isArray(formState[condition.ParameterIdentifier].value)) {
            for (const value of formState[condition.ParameterIdentifier].value) {
                if (condition.ComparisonValue.$values.includes(value)) {
                    // if any value IS present, it's not completely
                    // disjointed
                    return false;
                }
            }
            // if we made it through all values without returning false for
            // a present one, then all were missing.
            return true;
        }
        // value wasn't set or both condition and formstate were not arrays
        return false;
    } else if (condition.MatchType === "Intersection") {
        // - both condition.ComparisonValue and formState[id].value are sets
        // - ANY of the values are present in the ComparisonValue
        if (condition.ComparisonValue && condition.ComparisonValue.hasOwnProperty("$values") && Array.isArray(condition.ComparisonValue.$values) && Array.isArray(formState[condition.ParameterIdentifier].value)) {
            for (const value of formState[condition.ParameterIdentifier].value) {
                if (condition.ComparisonValue.$values.includes(value)) {
                    // if any value IS present, it has at least
                    // one intersection
                    return true;
                }
            }
            // if no intersections were found above, then it has
            // no intersections
            return false;
        }
        // value wasn't set or both condition and formstate were not arrays
        return false;
    } else {
        return false;
    }
}

export function geValuesApiUrl(field, formStateCopy, mode = "new", identifierToExcludeFromLinkedQuery = null, additionalQueryStringParameters = []) {
    let valuesApiUrl = null;
    if (field.GetValuesApi !== undefined && field.GetValuesApi !== null && field.GetValuesApi !== "") {
        // normal GetValuesAPI which may or may not have entity and query string items.
        // this is the logic tree for Select, Export.DataSelector, Import.DataSelector, DataSelector, and Review Page
        valuesApiUrl = bifrostAPIUrl + field.GetValuesApi;
        if (field.EntityNameProvisioningIdentifier !== null && field.EntityNameProvisioningIdentifier !== undefined && field.EntityNameProvisioningIdentifier !== "")
        {
            valuesApiUrl = valuesApiUrl.replace("{entityName}", formStateCopy[field.EntityNameProvisioningIdentifier].value);
        }

        const queryStringParameters = [...additionalQueryStringParameters];
        if (field.GetValuesApiQueryStringParameters) {
            for (const parameter of Object.keys(field.GetValuesApiQueryStringParameters)) {
                const parameterIdentifier = field.GetValuesApiQueryStringParameters[parameter];
                if (parameterIdentifier !== null && parameterIdentifier !== undefined && formStateCopy[parameterIdentifier] !== null && formStateCopy[parameterIdentifier] !== undefined && formStateCopy[parameterIdentifier].field && checkConditions(formStateCopy[parameterIdentifier].field.Conditions, formStateCopy)) {
                    if (Array.isArray(formStateCopy[parameterIdentifier].value)) {
                        if (formStateCopy[parameterIdentifier].value.length > 0) {
                            for (const arrayItem of formStateCopy[parameterIdentifier].value) {
                                queryStringParameters.push(`${ parameter  }=${  encodeURIComponent(arrayItem) }`);
                            }
                        }
                    } else {
                        queryStringParameters.push(`${ parameter  }=${  encodeURIComponent(formStateCopy[parameterIdentifier].value) }`);
                    }
                }
            }
        }
        // in a very specific case, where we are pulling from the provisioneddprocess API
        // to get a list of possible linked values, we need to add a specific querystring parameter
        // that tells that API that it's ok to return what is already configured.
        if (mode !== "new" && field.GetValuesApi.includes("/provisionedprocesses") && identifierToExcludeFromLinkedQuery) {
            queryStringParameters.push(`editProvisionedProcessIdentifier=${  identifierToExcludeFromLinkedQuery }`);
        }
        if (queryStringParameters.length > 0) {
            const queryStringAppender = field.GetValuesApi.includes("?") ? "&" : "?";
            valuesApiUrl = valuesApiUrl + queryStringAppender + queryStringParameters.join("&");
        }
        if (field.GetValuesApiPathParameters) {
            for (const getValuesApiPathPropertyName of Object.keys(field.GetValuesApiPathParameters)) {
                const getValuesApiPathPropertyProvisioningIdentifier = field.GetValuesApiPathParameters[getValuesApiPathPropertyName];
                valuesApiUrl = valuesApiUrl.replace(`{{${ getValuesApiPathPropertyName }}}`, encodeURIComponent(formStateCopy[getValuesApiPathPropertyProvisioningIdentifier].value));
            }            
        }
    } else if (field.ConvertToJsonApi !== null && field.ConvertToJsonApi !== undefined && field.ConvertToJsonApi !== "" ) {
        // this the logic path for the FileLayoutSelector
        // It has a complicated query string calculation that requires them to be passed in
        // since validation is done in that component on the parameters being valid.
        const queryStringParameters = [...additionalQueryStringParameters];
        const queryStringAppender = field.ConvertToJsonApi.includes("?") ? "&" : "?";
        valuesApiUrl = bifrostAPIUrl + field.ConvertToJsonApi + queryStringAppender + queryStringParameters.join("&");
    }
    if (valuesApiUrl && valuesApiUrl.includes("{{")) {
        return null;
    }
    return valuesApiUrl;
}

export function getFieldType(field) {
    let [fullFieldType] = field.$type.split(",");
    fullFieldType = fullFieldType.replace("MIP.Provisioning.Forms.", "");
    fullFieldType = fullFieldType.replace("Specialized.", "");
    return fullFieldType;
}

export function flattenConvertJsonData (prefix, dataToFlatten) {
    const flattenedData = {};
    if (dataToFlatten === null || dataToFlatten === undefined) {
        flattenedData[prefix] = null;
    } else {
        if (typeof(dataToFlatten) === "object") {
            for (const key of Object.keys(dataToFlatten)) {
                const newPrefix = prefix === null ? key : (`${ prefix  }.${  key }`);
                if (typeof(dataToFlatten[key]) === "object") {
                    if (Array.isArray(dataToFlatten[key]) && dataToFlatten[key].length > 0) {
                        if (typeof(dataToFlatten[key][0]) === "object") {
                            for (const element of dataToFlatten[key]) {
                                assignWithoutOverwrite(flattenedData, flattenConvertJsonData(`${ newPrefix  }[]`, element));
                            }
                        } else {
                            flattenedData[`${ newPrefix  }[]`] = dataToFlatten[key].join(", ");
                        }
                    } else {
                        assignWithoutOverwrite(flattenedData, flattenConvertJsonData(newPrefix, dataToFlatten[key]));
                    }
                } else if (!Object.keys(flattenedData).includes(newPrefix)) {
                    flattenedData[newPrefix] = dataToFlatten[key];
                }
            }
        } else if (!Object.keys(flattenedData).includes(prefix)) {
            flattenedData[prefix] = dataToFlatten;
        }
    }
    return flattenedData;
}

function assignWithoutOverwrite(destinationObject, sourceObject) {
    for (const property of Object.keys(sourceObject)) {
        if (!Object.keys(destinationObject).includes(property)) {
            destinationObject[property] = sourceObject[property];
        }
    }
    return destinationObject;
}

export const MathOperations = {
    "Add": { Label: "Add", ReviewPageDescription: "Add" },
    "Divide": { Label: "Divide", ReviewPageDescription: "Divide by" },
    "Exponent": { Label: "Exponent", ReviewPageDescription: "Raise to the power of" },
    "Maximum": { Label: "Maximum", ReviewPageDescription: "Maximum" },
    "Minimum": { Label: "Minimum", ReviewPageDescription: "Miniumum" },
    "Modulus": { Label: "Modulus", ReviewPageDescription: "Modulus" },
    "Multiply": { Label: "Multiply", ReviewPageDescription: "Multiply by" },
    "Subtract": { Label: "Subtract", ReviewPageDescription: "Subtract" }
};

// this used to have "", // None since it can be optional
// which messed up the display, per BSS-1230. Not sure if removing it
// will break edit or not.
export const RoundOperations = [
    "Ceiling",
    "Floor",
    "Round"
];

export const JsonType = [
    "Boolean",
    "DateTime",
    "Double",
    "String"
];

export const ImportDataSelectorEntityPropertyUserEnteredDataDefaults = {
    source: "DataFile",
    dataFileLocation: "0",
    lookupCacheFilename: "",
    previousCustomPropertyKey: "",
    customPropertyKey: "",
    constantValue: "",
    formatOption: "None",
    formatString: "",
    formatStringFunctions: [],
    formatTypeCode: "String",
    parseString: "",
    destinationTimeZoneId: "",
    sourceTimeZoneId: "",
    applyCalculation: false,
    calculations: [],
    applyStringConcatenations: false,
    concatenations: [], // string concatenations
    concatenateDelimiter: ",",
    lookupEntityKeyByIdentifier: false,
    useLookupEntityKeyFallback: false,
    lookupEntityKeyFallback: "0",
    useDataMaps: false,
    dataMapName: "",
    urlSubstitution: false,
    urlSubstitutionTTL: "",
    urlSubstitutionKeyLength: ""
};

const formatFormatStringOptions = (previousFormatStringOptions) => {
    const formatStringOptions = [];
    if (Array.isArray(previousFormatStringOptions)) {
        for (const option of previousFormatStringOptions) {
            formatStringOptions.push({
                    FormatType: option.FormatType,
                    Value: option.Value !== undefined && option.Value !== null ? option.Value : "",
                    NewValue: option.NewValue !== undefined && option.NewValue !== null ? option.NewValue : "",
                    OldValue: option.OldValue !== undefined && option.OldValue !== null ? option.OldValue : "",
                    SplitOn: option.SplitOn !== undefined && option.SplitOn !== null ? option.SplitOn : "",
                    Length: option.Length !== undefined && option.Length !== null ? option.Length.toString() : "",
                    StartIndex: option.StartIndex !== undefined && option.StartIndex !== null ? option.StartIndex.toString() : "",
                    PaddingChar: option.PaddingChar !== undefined && option.PaddingChar !== null ? option.PaddingChar : "",
                    TotalWidth: option.TotalWidth !== undefined && option.TotalWidth !== null ? option.TotalWidth.toString() : "",
                    ReplacementValue: option.ReplacementValue !== undefined && option.ReplacementValue !== null ? option.ReplacementValue : "",
                    IgnoreNullAndDefaultValues: option.IgnoreNullAndDefaultValues === true,
                    TypeNameHandling: option.TypeNameHandling !== undefined && option.TypeNameHandling !== null ? option.TypeNameHandling : "None",
            });
        }
    }
    return formatStringOptions;
};


export function processDataSelectorChanges(formStateDispatch, formState, dataSelectorField, entityPropertiesData) {
    let formStateUpdated = false;
    let previousValuesApplied = false;

    let columnsInDataFile = [];
    if (Object.keys(formState).includes(dataSelectorField.FileLayoutSelectorProvisioningIdentifier)) {
        if (formState[dataSelectorField.FileLayoutSelectorProvisioningIdentifier].externalData.length > 0) {
            const flattenedData = flattenConvertJsonData(null, formState[dataSelectorField.FileLayoutSelectorProvisioningIdentifier].externalData[0]);
            columnsInDataFile = Object.keys(flattenedData);
        }
    }

    // entityPropertiesDataByName is used to overlay the current values onto the old object in case
    // something like "IsRequired" has changed.
    // the keys of this object is the list of property names from the current API call
    // and that is used to delete anything that is there from a previous call.
    const entityPropertiesDataByName = {};
    for (const property of entityPropertiesData) {
        entityPropertiesDataByName[property.Name] = property;
    }

    const formStateValueCopy = [...formState[dataSelectorField.Parameter.Identifier].value];

    // existing properties is an array of property names that already exist in the formStateValue array
    // existingProperties will be empty initially since formState[Identifier].value is not yet populated
    // it is populated once this loads in the data mapper itself.
    const existingProperties = [];

    // This is removing objects from the formStateValueCopy if it no longer exists
    // in the new entityProperties now stored in entityPropertiesData
    // This is so if you use the back button and choose a different entity when the API call requests
    // new entityPropertiesData we remove the ones that no longer exist if they had already been added to the
    // formState[].value
    let indexToCheck = 0;
    while(indexToCheck < formStateValueCopy.length) {
        if (!Object.keys(entityPropertiesDataByName).includes(formStateValueCopy[indexToCheck].Name)) {
            formStateUpdated = true;
            formStateValueCopy.splice(indexToCheck, 1);
        } else {
            existingProperties.push(formStateValueCopy[indexToCheck].Name);
            indexToCheck++;
        }
    }

    let nextDisplayIndex = 0;
    // entityPropertiesData here is the data from the API with the list of properties this entity has
    for (const property of entityPropertiesData) {
        // if existingProperties already contains this property it means we've applied the defaults
        // and previous values in a previous render.
        if (!existingProperties.includes(property.Name)) {
            const propertyCopy = Object.assign({}, property);
            propertyCopy.displayIndex = -1;

            // if there are previous values apply them on top of the userEnteredData
            let hasPreviousValues = false;
            if (formState[dataSelectorField.Parameter.Identifier].previousValue !== null && formState[dataSelectorField.Parameter.Identifier].previousValue !== undefined) {
                previousValuesApplied = true;

                for (const previousArgumentValue of formState[dataSelectorField.Parameter.Identifier].previousValue.Value) {
                    if (property.Name === previousArgumentValue.DestinationProperty) {
                        hasPreviousValues = true;
                        if (propertyCopy.displayIndex === -1) {
                            propertyCopy.displayIndex = nextDisplayIndex++;
                        }

                        let previousSource = "DataFile";
                        let previousConstantValue = "";
                        if (Object.keys(previousArgumentValue).includes("ConstantValue") && previousArgumentValue.ConstantValue !== null && previousArgumentValue.ConstantValue !== undefined)
                        {
                            previousSource = "Constant";
                            if (property.ValidConstantValuesOnImport && property.AllowMultipleValidConstantValuesOnImport === true) {
                                previousConstantValue = previousArgumentValue.ConstantValue.split(",");
                            } else {
                                previousConstantValue = previousArgumentValue.ConstantValue;
                            }
                        }

                        let previousFormatOption = "None";
                        let previousFormatTypeCode = "String";
                        let previousFormatString = "";
                        let previousParseString = "";
                        let previousDestinationTimeZoneId = "";
                        let previousSourceTimeZoneId = "";
                        const previousFormatStringFunctions = formatFormatStringOptions(previousArgumentValue.Format?.FormatStringOptions);
                        if (Object.keys(previousArgumentValue).includes("Format") && previousArgumentValue.Format !== null && previousArgumentValue.Format !== undefined)
                        {
                            previousFormatOption = previousArgumentValue.Format.FormatOption ?? "None";
                            previousFormatTypeCode = previousArgumentValue.Format.TypeCode;
                            // 0 is Boolean and they do not have Format strings
                            if (previousArgumentValue.Format.TypeCode === "DateTime") {
                                previousFormatString = previousArgumentValue.Format.FormatDateTimeFormatString ?? "";
                                previousParseString = previousArgumentValue.Format.ParseDateTimeFormatString ?? "";
                            } else if (previousArgumentValue.Format.TypeCode === "Double") {
                                previousFormatString = previousArgumentValue.Format.FormatDoubleFormatString ?? "";
                            } else if (previousArgumentValue.Format.TypeCode === "String") {
                                previousFormatString = "";
                            }
                            previousDestinationTimeZoneId = previousArgumentValue.Format.DestinationTimeZoneId ?? "";
                            previousSourceTimeZoneId = previousArgumentValue.Format.SourceTimeZoneId ?? "";
                        }

                        let previousApplyCalcuation = false;
                        const previousCalculations = [];
                        if (Object.keys(previousArgumentValue).includes("Calculations") && Array.isArray(previousArgumentValue.Calculations)) {
                            previousApplyCalcuation = true;
                            for (const calculation of previousArgumentValue.Calculations) {
                                const calcObject = {
                                    mathOperation: calculation.MathOperation,
                                    roundOperation: calculation.RoundOperation ?? ""
                                };
                                if (calculation.Value) {
                                    calcObject["source"] = "Constant";
                                    calcObject["mathOperand"] = calculation.Value;
                                } else {
                                    calcObject["source"] = calculation.SourceProperty;
                                    calcObject["mathOperand"] = 0;
                                }
                                previousCalculations.push(calcObject);
                            }
                        }

                        let previousApplyStringConcatenations = false;
                        const previousConcatenations = [];
                        if (Object.keys(previousArgumentValue).includes("StringConcatenations") && Array.isArray(previousArgumentValue.StringConcatenations)) {
                            previousApplyStringConcatenations = true;
                            for (const concatenation of previousArgumentValue.StringConcatenations) {
                                previousConcatenations.push({
                                    source: concatenation.SourceProperty ?? "Constant",
                                    value: concatenation.Value ?? ""
                                });
                            }
                        }

                        let previousConcatenateDelimiter = ",";
                        if (Object.keys(previousArgumentValue).includes("ConcatenateDelimiter") && previousArgumentValue.ConcatenateDelimiter !== null && previousArgumentValue.ConcatenateDelimiter !== undefined && previousArgumentValue.SourceProperty && previousArgumentValue.SourceProperty.endsWith("[]")) {
                            previousConcatenateDelimiter = previousArgumentValue.ConcatenateDelimiter;
                        }

                        const previousLookupCacheFilename = previousArgumentValue.LookupCacheFilename ?? "";
                        const previousLookupEntityKeyByIdentifier = previousArgumentValue.Lookup === true;
                        let previousUseLookupEntityKeyFallback = false;
                        let previousLookupEntityKeyFallback = "";
                        if (Object.keys(previousArgumentValue).includes("LookupEntityKeyFallback") && previousArgumentValue.LookupEntityKeyFallback !== null && previousArgumentValue.LookupEntityKeyFallback !== undefined) {
                            previousUseLookupEntityKeyFallback = true;
                            previousLookupEntityKeyFallback = previousArgumentValue.LookupEntityKeyFallback;
                        }

                        let previousUseDataMaps = false;
                        let previousDataMapName = "";
                        if (Object.keys(previousArgumentValue).includes("DataMapName") && previousArgumentValue.DataMapName !== null && previousArgumentValue.DataMapName !== undefined) {
                            previousUseDataMaps = true;
                            previousDataMapName = previousArgumentValue.DataMapName;
                        }

                        let previousDataFileLocation = 0;
                        if (previousArgumentValue.SourceProperty && previousArgumentValue.SourceProperty) {
                            previousDataFileLocation = columnsInDataFile.indexOf(previousArgumentValue.SourceProperty).toString();
                        }
                        if (previousDataFileLocation === "-1") {
                            previousDataFileLocation = "0";
                        }

                        let previousUrlSubstitution = false;
                        let previousUrlSubstitutionTTL = "";
                        let previousUrlSubstitutionKeyLength = "";
                        if (Object.keys(previousArgumentValue).includes("UrlSubstitution") && previousArgumentValue.UrlSubstitution !== null && previousArgumentValue.UrlSubstitution !== undefined)
                        {
                            previousUrlSubstitution = true;
                            previousUrlSubstitutionTTL = previousArgumentValue.UrlSubstitution.TTLHours;
                            previousUrlSubstitutionKeyLength = previousArgumentValue.UrlSubstitution.KeyLength;
                        }

                        if (propertyCopy.userEnteredData === undefined) {
                            propertyCopy.userEnteredData = [];
                        }

                        propertyCopy.userEnteredData.push({
                            source: previousSource,
                            dataFileLocation: previousDataFileLocation,
                            lookupCacheFilename: previousLookupCacheFilename,
                            previousCustomPropertyKey: previousArgumentValue.CustomPropertyName,
                            customPropertyKey: previousArgumentValue.CustomPropertyName === null ? "" : previousArgumentValue.CustomPropertyName,
                            constantValue: previousConstantValue,
                            formatOption: previousFormatOption,
                            formatString: previousFormatString,
                            formatStringFunctions: previousFormatStringFunctions,
                            parseString: previousParseString,
                            formatTypeCode: previousFormatTypeCode,
                            destinationTimeZoneId: previousDestinationTimeZoneId,
                            sourceTimeZoneId: previousSourceTimeZoneId,
                            applyCalculation: previousApplyCalcuation,
                            calculations: previousCalculations,
                            applyStringConcatenations: previousApplyStringConcatenations,
                            concatenations: previousConcatenations,
                            concatenateDelimiter: previousConcatenateDelimiter,
                            lookupEntityKeyByIdentifier: previousLookupEntityKeyByIdentifier,
                            useLookupEntityKeyFallback: previousUseLookupEntityKeyFallback,
                            lookupEntityKeyFallback: previousLookupEntityKeyFallback,
                            useDataMaps: previousUseDataMaps,
                            dataMapName: previousDataMapName,
                            urlSubstitution: previousUrlSubstitution,
                            urlSubstitutionKeyLength: previousUrlSubstitutionKeyLength,
                            urlSubstitutionTTL: previousUrlSubstitutionTTL
                        });
                    }
                }
            }

            if (property.IsRequired && !hasPreviousValues) {
                propertyCopy.displayIndex = nextDisplayIndex++;
                propertyCopy.userEnteredData = [];
                propertyCopy.userEnteredData.push(
                    {
                        ...ImportDataSelectorEntityPropertyUserEnteredDataDefaults, 
                        ...{ source: property.HasRegionDefault ? "RegionDefault" : "DataFile" },
                        ...{ lookupEntityKeyByIdentifier: !property.HasRegionDefault && property.AllowIdentifierLookup }
                    });
            }
            formStateValueCopy.push(propertyCopy);
            formStateUpdated = true;
        }
    }

    // overlay each property in case something like "Required" has changed.
    // we do not set the formStateUpdated to true because we do not want this to update
    // form state every time. Only if something else has also updated it. Otherwise I think we get
    // an infinite loop.
    for (const formStateIndex in formStateValueCopy) {
        if (Object.keys(entityPropertiesDataByName).includes(formStateValueCopy[formStateIndex].Name)) {
            Object.assign(formStateValueCopy[formStateIndex], entityPropertiesDataByName[formStateValueCopy[formStateIndex].Name]);
        }
    }

    // make sure any data file locations that reference non existant fields
    // are set back to 0.
    // one of the few times we use `for in` instead of `for of` since we are updating
    // in place.
    for (const formStateIndex in formStateValueCopy) {
        for (const userEnteredDataIndex in formStateValueCopy[formStateIndex].userEnteredData) {
            if (formStateValueCopy[formStateIndex].userEnteredData[userEnteredDataIndex].source === "DataFile" && parseInt(formStateValueCopy[formStateIndex].userEnteredData[userEnteredDataIndex].dataFileLocation) >= columnsInDataFile.length) {
                formStateUpdated = true;
                formStateValueCopy[formStateIndex].userEnteredData[userEnteredDataIndex].dataFileLocation = "0";
            }
        }
    }

    // Check to see if we have any missing "LinkedProperties" which happens when we have region defaults.
    // TODO: this may need to be redone to have a "count" instead of just an array
    // so that I know how many times a destination property is present?
    //const allExistingDestinationProperties = formState[dataSelectorField.Parameter.Identifier].previousValue.Value.filter((property) => property.DestinationProperty).map((property) => property.DestinationProperty);
    // TODO:
    //      Loop over the entityPropertiesData and count how many times a linked property is referenced
    //      And somehow know if it is part of an group? And then add it once per group if it is not
    //      already in the existingDestinationProperties enough times?
    //      this is specifically to see if the Region Defaults are set for OrderClassEntity Keys
    //      but could also be if the linked properties on an EntityProperty has changed since we 
    //      provisioned this?

    if (formStateUpdated) {
        formStateDispatch({type: "setFormValue", payload: { fieldIdentifier: dataSelectorField.Parameter.Identifier, data: formStateValueCopy}});
    }

    if (previousValuesApplied) {
        formStateDispatch({type: "deletePreviousValue", payload: { fieldIdentifier: dataSelectorField.Parameter.Identifier }});
    }
}

export function getAllFieldsInProvisionableItemForms(forms) {
    const allFields = {};
    for (const form of forms) {
        for (const field of form.Fields) {
            const fieldCopy = {...field};
            allFields[field.Parameter.Identifier] = fieldCopy;
        }
    }
    return allFields;
}

export function getProvisionableItemArgumentValue(parameterIdentifier, field, itemArguments) {
    if (field) {
        const fieldType = getFieldType(field);
        for (const arg of itemArguments) {
            if (arg.Identifier === parameterIdentifier) {
                if (fieldType === "SelectField") {
                    if (Array.isArray(field.Values)) {
                        for (const possibleValue of field.Values) {
                            if (possibleValue.Value === arg.ValueAsString) {
                                return possibleValue.Key;
                            }
                        }
                    }
                    return arg.ValueAsString;
                } else {
                    return arg.Value;
                }
            }
        }
    }
    return undefined;
}

export function getInitialStateFieldObject (field, allFields, previousArguments) {

    const fieldObject = {
        fieldType: "",
        field: null,
        formConditions: null,
        value: null,
        externalData: null,
        previousValue: null,
        touched: false,
        hasApi: false,
        isFetching: false,
        disabled: false
    };

    fieldObject.field = field;
    if (field.GetValuesApi !== null && field.GetValuesApi !== undefined) {
        fieldObject.hasApi = true;
        fieldObject.isFetching = true; // default to a fetching state.
    }
    const fieldType = getFieldType(field);
    fieldObject.fieldType = fieldType;

    fieldObject.value = field.Parameter.DefaultValueAsString;
    if (fieldType === "ConstantField") {    
        // Constants dont have default values, just values
        fieldObject.value = field.Value;
    } else if (fieldType === "CheckboxField") {
        // C# sends these as "True" and "False" strings so we use default value instead
        // of the string.
        fieldObject.value = field.Parameter.DefaultValue;
    } else if (fieldType === "SelectField") {
        if (["Select", "VerticalRadio", "HorizontalRadio"].includes(field.SelectType)) {
            // these are single selects
            // Some default values cant be null (i.e. an Int64) so
            // if the default value is not in the values list, pre-populate it with
            // the first option. If the intent is to have a null (empty) value as a possible
            // answer then the DefaultValueAsString will have a corresponding "" in the Values object.

            // if this is API driven lets just assume the data is present, if it's not it should 
            // get set to the first API driven result once the data is loaded.
            const hasApi = field.GetValuesApi !== null && field.GetValuesApi !== undefined && field.GetValuesApi !== "";

            const possibleValues = [];
            for (const kvp of field.Values) {
                possibleValues.push(kvp.Value);
            }
            if (hasApi || possibleValues.includes(field.Parameter.DefaultValueAsString)) {
                fieldObject.value = field.Parameter.DefaultValueAsString;
            } else {
                // since no valid options were presented, use the "first" value
                [fieldObject.value] = possibleValues;
            }
        } else if (["MultiSelect", "VerticalCheckboxGroup", "HorizontalCheckboxGroup"].includes(field.SelectType)) {
            // these are multi selects (arrays)
            if (field.Parameter.DefaultValueAsString === "") {
                // if it has no defaults we get an empty string
                fieldObject.value = [];
            } else {
                // Multi Selects get "[1,3]" (as a string) and need to be an array.
                // the .map(String) turns all the elements into strings using the magic of the String constructor
                try {
                    fieldObject.value = JSON.parse(field.Parameter.DefaultValueAsString).map(String);
                } catch (ex) {
                    console.error(`Error parsing DefaultValueAsString '${ field.Parameter.DefaultValueAsString }' for field ${ field.Parameter.Identifier }`);
                    // throwing anyway so we know its a problem.
                    throw(ex);
                }
            }
        }
    } else if (fieldType === "FileSelectorField") {
        // for file selectors we want it to be set to null;
        fieldObject.value = null;
    } else if (fieldType === "FileLayoutSelector") {
        fieldObject.value = null;
    } else if (fieldType === "Export.DataSelector") {
        fieldObject.value = {fields: [], arrayData: {}};
    } else if (["DataSelector", "Import.DataSelector", "FixedWidthColumnsSelector"].includes(fieldType)) {
        fieldObject.value = [];
    }

    // if there are previous arguments let go over them and either store them
    // or overwrite the current value with the previous one.
    if (previousArguments !== null && previousArguments !== undefined) {
        for (const previousData of previousArguments) {
            if (previousData.Identifier === field.Parameter.Identifier) {
                if (fieldType === "CheckboxField") {
                    fieldObject.value = previousData.Value;
                } else if (fieldType === "SelectField" && ["MultiSelect", "VerticalCheckboxGroup", "HorizontalCheckboxGroup"].includes(field.SelectType)) {
                    // selects of 3,4,5 are multi selects and need to be arrays
                    if (previousData.ValueAsString === "") {
                        // if it has nothing selected we get an empty string 
                        fieldObject.value = [];
                    } else {
                        // Multi Selects get "[1,3]" (as a string) and need to be an array.
                        // the .map(String) turns all the elements into strings using the magic of the String constructor
                        try {
                            fieldObject.value = JSON.parse(previousData.ValueAsString).map(String);
                        } catch (ex) {
                            console.error(`Error parsing previous ValueAsString '${ previousData.ValueAsString }' for field ${ field.Parameter.Identifier }`);
                            // throwing anyway so we know its a problem.
                            throw(ex);
                        }
                    }
                } else if (fieldType === "FileSelectorField") {
                    // previously uploaded file needs to be formated
                    // like a fileInfo array
                    fieldObject.value = [{name: previousData.Value}];
                } else if (fieldType === "FileLayoutSelector") {
                    // previously uploaded file needs to be formated
                    // like a fileInfo array
                    fieldObject.value = [{name: previousData.Value.Filename}];
                    // Column Data is stored in the Columns object.
                    const previousColumnData = {};
                    if (Array.isArray(previousData.Value.ColumnNames)) {
                        for (const columnName of previousData.Value.ColumnNames) {
                            // just need the columnName to be in the object. We don't know the other data
                            previousColumnData[columnName] = "Not available when editing.";
                        }
                    }
                    fieldObject.externalData = [previousColumnData];
                } else if (fieldType === "Export.DataSelector") {
                    const baseObject = {
                        fields: [],
                        arrayData: {}
                    };
                    if (Object.keys(previousData.Value).includes("Items")) {
                        for (const field of previousData.Value.Items) {
                            const previousSource = Object.keys(field).includes("ConstantValue") ? "Constant" : "Entity";

                            let previousFormatString = "";
                            let previousParseString = "";

                            if (Object.keys(field).includes("Format") && field.Format !== null && field.Format !== undefined)
                            {
                                // 0 is Boolean and they do not have Format strings
                                if (field.Format.TypeCode === "DateTime") {
                                    previousFormatString = field.Format.FormatDateTimeFormatString ?? "";
                                    previousParseString = field.Format.ParseDateTimeFormatString ?? "";
                                } else if (field.Format.TypeCode === "Double") {
                                    previousFormatString = field.Format.FormatDoubleFormatString ?? "";
                                } else if (field.Format.TypeCode === "String") {
                                    previousFormatString = "";
                                }
                            }

                            const fieldObject = {
                                destinationProperty: previousSource === "Constant" && field.DestinationProperty !== undefined && field.DestinationProperty !== null ? field.DestinationProperty : "",
                                destinationPropertyOverride: previousSource === "Entity" && field.DestinationPropertyOverride !== undefined && field.DestinationPropertyOverride !== null ? field.DestinationPropertyOverride : "",
                                customPropertyKey: field.CustomPropertyName ?? "",
                                source: previousSource,
                                entityProperty: field.SourceProperty ?? "",
                                constantValue: field.ConstantValue ?? "",
                                formatOption: field.Format ? field.Format.FormatOption : "None",
                                formatString: previousFormatString,
                                formatStringFunctions: formatFormatStringOptions(field.Format?.FormatStringOptions),
                                parseString: previousParseString,
                                sourceTimeZoneId:  field.Format ? field.Format.SourceTimeZoneId : "",
                                destinationTimeZoneId:  field.Format ? field.Format.DestinationTimeZoneId : "",
                                useDataMaps: field.DataMapName !== undefined && field.DataMapName !== null ? true : false,
                                dataMapName: field.DataMapName !== undefined && field.DataMapName !== null ? field.DataMapName : "",
                                applyCalculation: field.Calculations !== undefined && field.Calculations !== null ? true : false,
                                calculations: [],
                                applyStringConcatenations: field.StringConcatenations !== undefined && field.StringConcatenations !== null ? true : false,
                                concatenations: []
                            };
                            if (field.Calculations && Array.isArray(field.Calculations)) {
                                const calculations = [];
                                for (const calculation of field.Calculations) {
                                    const calcObject = {
                                        mathOperation: calculation.MathOperation,
                                        roundOperation: calculation.RoundOperation ?? ""
                                    };
                                    if (calculation.Value) {
                                        calcObject["source"] = "Constant";
                                        calcObject["mathOperand"] = calculation.Value;
                                    } else {
                                        calcObject["source"] = calculation.SourceProperty;
                                        calcObject["mathOperand"] = 0;
                                    }
                                    calculations.push(calcObject);
                                }
                                fieldObject.calculations = calculations;
                            }
                            if (field.StringConcatenations && Array.isArray(field.StringConcatenations)) {
                                const concatenations = [];
                                for (const concatenation of field.StringConcatenations) {
                                    const concatObject = {
                                        source: concatenation.Value ? "Constant" : concatenation.SourceProperty ?? "",
                                        value: concatenation.Value ?? ""
                                    };
                                    concatenations.push(concatObject);
                                }
                                fieldObject.concatenations = concatenations;
                            }
                            baseObject.fields.push(fieldObject);
                        }
                    }
                    if (Object.keys(previousData.Value).includes("FlatArraySettings") && previousData.Value.FlatArraySettings !== null) {
                        for (const flatArrayInfo of previousData.Value.FlatArraySettings) {
                            baseObject.arrayData[flatArrayInfo.PropertyPath] = {
                                NumberOfColumns: flatArrayInfo.NumberOfColumns === null ? 1 : flatArrayInfo.NumberOfColumns,
                                VerticalOrHorizontal: flatArrayInfo.NumberOfColumns === null ? "vertical" : "horizontal"
                            };
                        }
                    }
                    fieldObject.value = baseObject;
                } else if (["DataSelector", "Import.DataSelector"].includes(fieldType)) {
                    // data selectors are the one field (so far) that need to know its previous arguments
                    // after getting API data for the first time.
                    fieldObject.previousValue = previousData;
                } else if (fieldType === "FixedWidthColumnsSelector") {
                    fieldObject.value = previousData.Value;
                } else {
                    // all other fields get the valueAsString, but some need to be parsed
                    // it is counter intiutive, but TreatValueAsJson is the ones we DONT want to treat as JSON
                    if (!fieldObject.field.Parameter.TreatValueAsJson && Array.isArray(previousData.Value)) {
                        fieldObject.value = previousData.Value.map(String).join(", ");
                    } else {
                        fieldObject.value = previousData.ValueAsString;
                    }
                }
            }
        }
    }
    return fieldObject;
};


export function getSelectFieldDisplayValuesFromData(field, externalData, currentFieldValue) {
    const kvpLookup = {};
    for (const kvp of field.Values) {
        kvpLookup[kvp.Value] = kvp.Key;
    }

    if (externalData !== undefined) {
        // adding data from API to kvpLookup
        let dataToParse = null;
        if (externalData !== null && typeof(externalData) === "object") {
            if (field.GetValuesApiResultSet !== null && Object.keys(externalData).includes(field.GetValuesApiResultSet)) {
                dataToParse = externalData[field.GetValuesApiResultSet];
            } else if (Array.isArray(externalData)) {
                dataToParse = externalData;
            }
        }
        if (dataToParse !== null) {
            for (const dataObject of dataToParse) {
                kvpLookup[dataObject[field.GetValuesApiResultValue].toString()] = dataObject[field.GetValuesApiResultKey].toString();
            }
        }
    }

    const presentValues = [];
    const missingValues = [];
    if (["Select", "VerticalRadio", "HorizontalRadio"].includes(field.SelectType)) {
        // single select
        if (Object.keys(kvpLookup).includes(currentFieldValue.toString())) {
            presentValues.push(kvpLookup[currentFieldValue]);
        }
    } else if (["MultiSelect", "VerticalCheckboxGroup", "HorizontalCheckboxGroup"].includes(field.SelectType)) {
        // Multi selects
        for (const selectedValue of currentFieldValue) {
            if (Object.keys(kvpLookup).includes(selectedValue)) {
                presentValues.push(kvpLookup[selectedValue]);
            } else {
                missingValues.push(selectedValue);
            }
        }
    }
    return {
        presentValues,
        missingValues
    };
}

export function formatDuration(milliseconds) {
    if (milliseconds < 1000) {
        return `${ milliseconds } ms`;
    }
    //const hours = Math.floor(milliseconds / 3600000); // 1 hour = 3600000 milliseconds ${ hours.toString().padStart(2, "0") }:
    const minutes = Math.floor((milliseconds % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const seconds = Math.floor((milliseconds % 60000) / 1000); // 1 second = 1000 milliseconds
    const remainingMilliseconds = milliseconds % 1000; 
    return `${ minutes.toString().padStart(2, "0") }:${ seconds.toString().padStart(2, "0") }.${ remainingMilliseconds.toString().padStart(3, "0") }`;
}      

export function formatFileSizeAsHumanReadable (bytes) {
    if (bytes === 0) return "0 KB";
    const sizeInKB = bytes / 1024;
    if (bytes < 1024) {
        return `${ sizeInKB.toFixed(2) } KB`;
    }
    const units = ["KB", "MB", "GB", "TB"];
    const unitIndex = Math.floor(Math.log(sizeInKB) / Math.log(1024));
    const size = sizeInKB / Math.pow(1024, unitIndex);
    return `${ size.toFixed(2) } ${ units[unitIndex] }`;
}

export function formatDateAsISO (dateToFormat, includeMs=true) {
    const year = dateToFormat.getFullYear();
    const month = (dateToFormat.getMonth() + 1).toString().padStart(2, "0");
    const day = dateToFormat.getDate().toString().padStart(2, "0");
    const hours = dateToFormat.getHours().toString().padStart(2, "0");
    const minutes = dateToFormat.getMinutes().toString().padStart(2, "0");
    const seconds = dateToFormat.getSeconds().toString().padStart(2, "0");
    const milliseconds = dateToFormat.getMilliseconds().toString().padStart(3, "0");
    if (includeMs) {
        return `${ year }-${ month }-${ day }T${ hours }:${ minutes }:${ seconds }.${ milliseconds }`;
    } else {
        return `${ year }-${ month }-${ day }T${ hours }:${ minutes }:${ seconds }`;
    }
};

export function getAdminLinks(privilege) {
    const links = [];
    if (privilege.includes("Admin") || privilege.includes("All")) {
        links.push({
            path: "/admin/users",
            title: "Users",
            key: "users"
        });
    }
    if (privilege.includes("ManageCustomers") || privilege.includes("All")) {
        links.push({
            path: "/admin/customers",
            title: "Customers",
            key: "customers"
        });
    }

    links.sort((a, b) => ((a.title < b.title) ? -1 : 1));
    return links;
}

export function getSettingsLinks(privilege) {
    const links = [];
    if (privilege.includes("ProvisionProcesses") || privilege.includes("All")) {
        links.push({
            path: "/settings/data-maps",
            title: "Data Maps",
            key: "data-maps"
        });
        links.push({
            path: "/settings/api-map",
            title: "API Map",
            key: "api-map"
        });
        links.push({
            path: "/settings/order-reservation-windows",
            title: "Order Reservation Windows",
            key: "order-reservation-windows"
        });
        links.push({
            path: "/settings/email-distribution-lists",
            title: "Email Distribution Lists",
            key: "email-distribution-lists"
        });
        links.push({
            path: "/settings/task-result-actions",
            title: "Task Result Actions",
            key: "task-result-actions"
        });
        links.push({
            path: "/settings/external-entities/systems",
            title: "External Entities",
            key: "external-entities"
        });
    }
    if (privilege.includes("ProvisionResources")|| privilege.includes("All")) {
        links.push({
            path: "/settings/api-keys",
            title: "API Keys",
            key: "api-keys"
        });
    }
    if (privilege.includes("Admin") || privilege.includes("All")) {
        links.push({
            path: "/settings/general",
            title: "General Settings",
            key: "general"
        });
        links.push({
            path: "/settings/extended",
            title: "Extended Settings",
            key: "extended"
        });
        links.push({
            path: "/settings/kill-tasks",
            title: "Kill Tasks",
            key: "kill-tasks"
        });
    }
    if (privilege.includes("UrlSubstitutions")|| privilege.includes("All")) {
        links.push({
            path: "/settings/url-substitutions",
            title: "URL Substitutions",
            key: "url-substitutions"
        });
    }
    links.sort((a, b) => ((a.title < b.title) ? -1 : 1));
    return links;
}