import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
    selectIsLoggedIn,
    selectPrivilege
} from "../../features/auth/authSlice";
import UserInformationBanner from "../../components/Common/UserInformationBanner";
import NotFound from "../../components/Common/NotFound";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import ErrorTile from "../../components/Common/ErrorTile";
import LogsTable from "../../components/Logs/LogsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretUp,
    faCaretRight,
    faSpinner,
    faArrowRotateBackward,
    faArrowRotateForward,
    faPager,
    faClock
} from "@fortawesome/free-solid-svg-icons";
import { mipApi } from "../../services/mip";
import { formatDateAsISO } from "../../midgard.js";


export default function Logs(props) {   
    const navigate = useNavigate();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const privilege = useSelector(selectPrivilege);
    const hasPrivilege = privilege.includes("Logs") || privilege.includes("All");

    const [referenceTimestampInputValue, setReferenceTimestampInputValue] = useState(formatDateAsISO(new Date()));
    const [logLevel, setLogLevel] = useState("DEBUG");
    const [expandOptions, setExpandOptions] = useState(true);
    const [useUTC, setUseUTC] = useState(false);
    const [expandAllLogs, setExpandAllLogs] = useState(false);
    const [globalLogs, setGlobalLogs] = useState(false);
    const [lambdaRequestIdentifier, setLambdaRequestIdentifier] = useState("");
    const [collectedLogs, setCollectedLogs] = useState({});
    const [isResetting, setIsResetting] = useState(false);
    const [timestampValidationError, setTimestampValidationError] = useState();
    const [hideLoadButtons, setHideLoadButtons] = useState(false);

    const offset = (new Date().getTimezoneOffset()) / -60;

    useEffect(() => {
        document.title = "Administration - Logs";
    }, []);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    const updateTimestampToNow = () => {
        if (useUTC) {
            setReferenceTimestampInputValue((new Date()).toISOString());
        } else {
            setReferenceTimestampInputValue(formatDateAsISO(new Date()));
        }
    };

    const toggleUseUTC = (value) => {
        const currentTimeStampValue = referenceTimestampInputValue;
        if (value) { 
            // if we are setting UTC to true then referenceTimeStamp is currently in local
            // this converts it back to UTC
            setReferenceTimestampInputValue(new Date(currentTimeStampValue).toISOString());
        } else {
            // if we are going from UTC to Local...
            setReferenceTimestampInputValue(formatDateAsISO(new Date(currentTimeStampValue)));
        }
        setUseUTC(value);
    };

    const [fetchLogsFunction, { data, isLoading, isFetching, error }] = mipApi.endpoints.getLogs.useLazyQuery();
    const fetchInitialLogs = async (lookBackwards) => {
        setIsResetting(true);
        setHideLoadButtons(false);
        setTimestampValidationError(undefined);
        setLambdaRequestIdentifier("");
        let referenceTimestampToSend = undefined;
        try {
            referenceTimestampToSend = useUTC ? referenceTimestampInputValue : new Date(referenceTimestampInputValue).toISOString();
        } catch (ex) {
            setTimestampValidationError(ex);
        }
        if (referenceTimestampToSend) {
            setCollectedLogs({});
            await fetchLogsFunction({
                globalLogs,
                lambdaRequestIdentifier: "",
                pageToken: referenceTimestampToSend,
                orderByDescending: lookBackwards
            });
        }
        setIsResetting(false);
    };

    const fetchAdditionalLogs = (lookBackwards) => {
        const allTimeStamps = Object.values(collectedLogs).map((logEntry) => new Date(`${ logEntry.Timestamp }Z`));
        let referenceTimestamp = new Date();
        if (lookBackwards) {
            referenceTimestamp = new Date(Math.min.apply(null, allTimeStamps)).toISOString();
        } else {
            referenceTimestamp = new Date(Math.max.apply(null, allTimeStamps)).toISOString();
        }
        fetchLogsFunction({
            globalLogs,
            lambdaRequestIdentifier: "",
            pageToken: referenceTimestamp,
            orderByDescending: lookBackwards
        });
    };

    const queryByLambdaRequestIdentifier = async (lriGuid) => {
        setIsResetting(true);
        setHideLoadButtons(true);
        if (typeof(lriGuid) === "string") {
            setLambdaRequestIdentifier(lriGuid);
        }
        setTimestampValidationError(undefined);
        setCollectedLogs({});
        await fetchLogsFunction({
            globalLogs: false,
            // if it is passed in, use it, otherwise use the one in the field
            lambdaRequestIdentifier: typeof(lriGuid) === "string" ? lriGuid : lambdaRequestIdentifier,
            pageToken: undefined,
            orderByDescending: false
        });
        setIsResetting(false);
    };

  
    useLayoutEffect(() => {
        // since this runs when we "clear" setLogs, but the old data was still present
        // it was not really clearing. Adding the !isFetching seems to have fixed that
        // so this only runs once data.Data has completed fetching.
        if (!isFetching && Array.isArray(data?.Data?.Page)) {
            const collectedLogsCopy = {...collectedLogs};
            let newData = false;
            for (const logData of data.Data.Page) {
                if (!collectedLogsCopy.hasOwnProperty(logData.Identifier)) {
                    newData = true;
                    collectedLogsCopy[logData.Identifier] = logData;
                }
            }
            if (newData) {
                setCollectedLogs(collectedLogsCopy);
            }
        }
    }, [data, collectedLogs, isFetching]);

    if (!hasPrivilege) {
        return <NotFound message="Something's not right." />;
    }

    return (
        <>
        <UserInformationBanner />
        <div className="bg-white">
            <div className="p-4">
                <div className="mx-auto mb-10 bg-white rounded-lg">
                    <div className="text-2xl pb-4 flex">
                        <div className="flex-1 p-1"><FontAwesomeIcon icon={faPager} /> Logs</div>
                    </div>
                    <div className="border p-2">
                        {
                            expandOptions
                                ?   <div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="initialTimestamp">
                                                    Reference Timestamp
                                                </label>
                                            </div>
                                            <input id="initialTimestamp" type="text" value={referenceTimestampInputValue} onChange={(e) => setReferenceTimestampInputValue(e.target.value)} className="shadow appearance-none border rounded w-11/12 py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            <button onClick={updateTimestampToNow} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/12"><FontAwesomeIcon icon={faClock} /> Now</button>
                                        </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="lambdaRequestIdentifier">
                                                    Lambda Request Identifier
                                                </label>
                                            </div>
                                            <input type="text" value={lambdaRequestIdentifier} onChange={(e) => setLambdaRequestIdentifier(e.target.value)} className="shadow appearance-none border rounded w-11/12 py-2 pl-3 pr-6 text-grey-800 mb-3" />
                                            <button onClick={queryByLambdaRequestIdentifier} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/12">Query</button>
                                        </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="LogLevel">
                                                    Log Level
                                                </label>
                                            </div>
                                            <select name="LogLevel" id="LogLevel" value={logLevel} onChange={(e) => {setLogLevel(e.target.value);}} className="shadow border rounded w-full py-2 pl-3 pr-8 text-grey-800 mb-3 text-base focus:shadow-outline">
                                                <option value="DEBUG">DEBUG</option>
                                                <option value="INFO">INFO</option>
                                                <option value="WARN">WARN</option>
                                                <option value="ERROR">ERROR</option>
                                                <option value="FATAL">FATAL</option>
                                            </select>
                                        </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="globalLogs">
                                                    Log Type
                                                </label>
                                            </div>
                                            <label className="ml-4">
                                                <input type="radio" name="globalLogs" id="globalLogs" value="customer" checked={!globalLogs} className="align-middle" onChange={(e) => setGlobalLogs(false)} />
                                                <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">Customer</span>
                                            </label>
                                            <label className="ml-4">
                                                <input type="radio" name="globalLogs" id="globalLogs" value="global" checked={globalLogs} className="align-middle" onChange={(e) => setGlobalLogs(true)} />
                                                <span className="text-grey-800 text-sm font-bold mb-2 ml-2 align-middle">Global</span>
                                            </label>
                                        </div>
                                        <div className="my-2">
                                            <div className="mb-4">
                                                <label className="text-grey-800 text-sm font-bold align-middle" htmlFor="globalLogs">
                                                    Additional Options
                                                </label>
                                            </div>
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor="useUTC">
                                                    <input type="checkbox" name="useUTC" id="useUTC" value={useUTC} checked={useUTC ? "checked" : ""} onChange={(e) => {toggleUseUTC(e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle">Use UTC</span>
                                                </label>
                                            </div>
                                            <div className="m-1 mb-2 pl-2">
                                                <label className="align-middle" htmlFor="expandAllLogs">
                                                    <input type="checkbox" name="expandAllLogs" id="expandAllLogs" value={expandAllLogs} checked={expandAllLogs ? "checked" : ""} onChange={(e) => {setExpandAllLogs(e.target.checked);}} className="mr-2 align-middle" />
                                                    <span className="text-grey-800 align-middle">Expand All</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="flex justify-evenly">
                                            <button data-test-id="load-prior-to-reference-button" disabled={isFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => fetchInitialLogs(true)}>{isFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span><FontAwesomeIcon icon={faArrowRotateBackward} /> Load Prior to Reference</span>}</button>
                                            <button data-test-id="load-after-reference-button" disabled={isFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" type="button" onClick={() => fetchInitialLogs(false)}>{isFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span><FontAwesomeIcon icon={faArrowRotateForward} /> Load After Reference</span>}</button>
                                        </div>
                                    </div>
                                : null
                        }
                        <div className="flex-initial text-right text-sm hover:cursor-pointer" onClick={() => setExpandOptions(!expandOptions)}>Log Options {expandOptions ? <FontAwesomeIcon icon={faCaretUp}  className="w-3" /> : <FontAwesomeIcon icon={faCaretRight} className="w-3" />}</div>
                    </div>
                    { 
                        timestampValidationError 
                            ? <ErrorTile message={timestampValidationError} />
                            : null
                    }
                    { 
                        error 
                            ? <ErrorTile message={error} />
                            : null
                    }
                    {
                        isLoading || isResetting
                            ? <LoadingSpinner text="Fetching logs" />
                            : Object.keys(collectedLogs).length === 0
                                ?   <div data-test-id="no-log-entries-found" className="text-center pt-4">No log entries found.</div>
                                :   <div className="pt-4">
                                        {
                                            !hideLoadButtons
                                                ?   <div className="flex justify-evenly">
                                                        <button disabled={isFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-2" type="button" onClick={() => fetchAdditionalLogs(true)}>{isFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span><FontAwesomeIcon icon={faArrowRotateBackward} /> Load Older</span>}</button>
                                                    </div>
                                                : null
                                        }
                                        <LogsTable
                                            entries={Object.values(collectedLogs).sort((a,b) => (a.Timestamp > b.Timestamp ? 1 : ((a.Timestamp < b.Timestamp) ? -1 : 0)))}
                                            offset={offset}
                                            useUTC={useUTC}
                                            expandAll={expandAllLogs}
                                            logLevel={logLevel}
                                            hideLoadButtons={hideLoadButtons}
                                            queryByLambdaRequestIdentifier={queryByLambdaRequestIdentifier}
                                        />
                                         {
                                            !hideLoadButtons
                                                ?   <div className="flex justify-evenly pt-2">
                                                        <button disabled={isFetching} className="inline bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2" type="button" onClick={() => fetchAdditionalLogs(false)}>{isFetching ? <FontAwesomeIcon icon={faSpinner} className="spinner" /> : <span><FontAwesomeIcon icon={faArrowRotateForward} /> Load Newer</span>}</button>
                                                    </div>
                                                : null
                                         }
                                    </div>
                    }
                </div>
            </div>
        </div>
        </>
    );
}