import { getFieldType, geValuesApiUrl, flattenConvertJsonData, MathOperations } from "../../../midgard.js";
import { useGetExternalDataQuery } from "../../../services/mip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faWarning } from "@fortawesome/free-solid-svg-icons";

export default function ProvisionableItemReviewPageRow(props) { 

    const fieldType = getFieldType(props.field);
    const hasApi = props.field.GetValuesApi !== undefined && props.field.GetValuesApi !== null && props.field.GetValuesApi !== "";
    const externalDataApiUrl = geValuesApiUrl(props.field, props.formState, props.provisionMode, ["Import.DataSelector", "DataSelector"].includes(fieldType) ? null : props.identifierToExcludeFromLinkedQuery);
    const { 
        data,
        error,
        isFetching,
    } = useGetExternalDataQuery(externalDataApiUrl, { skip: !hasApi });
    
    let fieldValue = props.formState[props.field.Parameter.Identifier].value;
    if (fieldType === "SelectField") {
        const kvpLookup = {};
        for (const kvp of props.field.Values) {
            kvpLookup[kvp.Value] = kvp.Key;
        }

        if (data !== undefined) {
            // adding data from API to kvpLookup
            let dataToParse = null;
            if (data !== null && typeof(data) === "object") {
                if (props.field.GetValuesApiResultSet !== null && Object.keys(data).includes(props.field.GetValuesApiResultSet)) {
                    dataToParse = data[props.field.GetValuesApiResultSet];
                } else if (Array.isArray(data)) {
                    dataToParse = data;
                }
            }
            if (dataToParse !== null) {
                for (const dataObject of dataToParse) {
                    kvpLookup[dataObject[props.field.GetValuesApiResultValue].toString()] = dataObject[props.field.GetValuesApiResultKey].toString();
                }
            }
        }
        
        if (["Select", "VerticalRadio", "HorizontalRadio"].includes(props.field.SelectType)) {
            // single select
            if (Object.keys(kvpLookup).includes(props.formState[props.field.Parameter.Identifier].value)) {
                fieldValue = kvpLookup[props.formState[props.field.Parameter.Identifier].value];
            } else {
                fieldValue = <div className="text-orange-500 font-bold"><FontAwesomeIcon icon={faWarning} /> Missing value: {props.formState[props.field.Parameter.Identifier].value}</div>;
            }
        } else if (["MultiSelect", "VerticalCheckboxGroup", "HorizontalCheckboxGroup"].includes(props.field.SelectType)) {
            // Multi selects
            const presentValues = [];
            const missingValues = [];
            for (const selectedValue of props.formState[props.field.Parameter.Identifier].value) {
                if (Object.keys(kvpLookup).includes(selectedValue)) {
                    presentValues.push(kvpLookup[selectedValue]);
                } else {
                    missingValues.push(selectedValue);
                }
            }
            fieldValue = [];
            if (missingValues.length !== 0) {
                fieldValue.push(<div key="missing-value-warning" className="text-orange-500 font-bold"><FontAwesomeIcon icon={faWarning} /> Missing value(s): {missingValues.join(", ")}</div>);
            }
            if (presentValues.length !== 0) {
                fieldValue.push(<div key="select-field-values">{presentValues.join(", ")}</div>);
            }
        }
    } else if (fieldType === "CheckboxField") {
        fieldValue = props.formState[props.field.Parameter.Identifier].value ? "True" : "False";
    } else if (fieldType === "TextField" && props.field.TextType === "Password") {
        fieldValue = "[password not displayed]";
    } else if (fieldType === "Export.DataSelector") {
        let counter = 1;
        const mappingFieldDisplays = [];
        for (const mappingField of props.formState[props.field.Parameter.Identifier].value.fields) {
            const mappingFieldInfoRows = [];
            if (mappingField.source === "Entity") {
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="source"><div className="p-2 border align-top text-left font-bold">Source</div><div className="border p-2 break-words">Entity Property</div></div>);
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="constantValue"><div className="p-2 border align-top text-left font-bold">Property</div><div className="border p-2 break-words">{mappingField.entityProperty}</div></div>);
                if (mappingField.entityProperty.endsWith("CustomProperties")) {
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="customPropertyKey"><div className="p-2 border align-top text-left font-bold">Property Key</div><div className="border p-2 break-words">{mappingField.customPropertyKey}</div></div>);
                }
            } else if (mappingField.source === "Constant") {
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="source"><div className="p-2 border align-top text-left font-bold">Source</div><div className="border p-2 break-words">Constant</div></div>);
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="constantValue"><div className="p-2 border align-top text-left font-bold">Value</div><div className="border p-2 break-words">{mappingField.constantValue}</div></div>);
            }
            
            if (mappingField.destinationPropertyOverride !== "") {
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="destinationPropertyOverride"><div className="p-2 border align-top text-left font-bold">Destination Override</div><div className="border p-2 break-words">{mappingField.destinationPropertyOverride}</div></div>);
            }
            if (mappingField.destinationProperty !== "") {
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="destinationProperty"><div className="p-2 border align-top text-left font-bold">Destination</div><div className="border p-2 break-words">{mappingField.destinationProperty}</div></div>);
            }
            if (mappingField.formatOption !== "None") {
                if (mappingField.parseString !== "") {
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="parseString"><div className="p-2 border align-top text-left font-bold">Parse String</div><div className="border p-2 break-words">{mappingField.parseString}</div></div>);
                }
                if (mappingField.formatString !== "") {
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="formatString"><div className="p-2 border align-top text-left font-bold">Format String</div><div className="border p-2 break-words">{mappingField.formatString}</div></div>);
                }
                if (Array.isArray(mappingField.formatStringFunctions) && mappingField.formatStringFunctions.length > 0) {
                    const formatStringFunctionDescriptions = [];
                    let index = 0;
                    for (const formatStringfunction of mappingField.formatStringFunctions) {
                        if (formatStringfunction.FormatType === "Replace") {
                            formatStringFunctionDescriptions.push(<div key={index}>{`Replace(OldValue = "${ formatStringfunction.OldValue }", NewValue = "${ formatStringfunction.NewValue }")`}</div>);
                        } else if (["PadLeft", "PadRight"].includes(formatStringfunction.FormatType)) {
                            formatStringFunctionDescriptions.push(<div key={index}>{`${ formatStringfunction.FormatType }(PaddingChar = "${ formatStringfunction.PaddingChar }", TotalWidth = ${ formatStringfunction.TotalWidth })`}</div>);
                        } else if (["SplitLeft", "SplitRight"].includes(formatStringfunction.FormatType)) {
                            formatStringFunctionDescriptions.push(<div key={index}>{`${ formatStringfunction.FormatType }(SplitOn = "${ formatStringfunction.SplitOn }")`}</div>);
                        } else if (formatStringfunction.FormatType === "Insert") {
                            formatStringFunctionDescriptions.push(<div key={index}>{`Insert(StartIndex = ${ formatStringfunction.StartIndex }, Value = ${ formatStringfunction.StartIndex })`}</div>);
                        } else if (formatStringfunction.FormatType === "Substring") {
                            if (formatStringfunction.Length !== "") {
                                formatStringFunctionDescriptions.push(<div key={index}>{`Substring(StartIndex = ${ formatStringfunction.StartIndex }, Length = ${ formatStringfunction.Length })`}</div>);
                            } else {
                                formatStringFunctionDescriptions.push(<div key={index}>{`Substring(StartIndex = ${ formatStringfunction.StartIndex })`}</div>);
                            }
                        } else if (formatStringfunction.FormatType === "StripLineBreaks") {
                            formatStringFunctionDescriptions.push(<div key={index}>{`StripLineBreaks(ReplacementValue = "${ formatStringfunction.ReplacementValue }")`}</div>);
                        } else if (formatStringfunction.FormatType === "JsonSerialize") {
                            formatStringFunctionDescriptions.push(<div key={index}>{`JsonSerialize(IgnoreNullAndDefaultValues = ${ formatStringfunction.IgnoreNullAndDefaultValues }, TypeNameHandling = "${ formatStringfunction.TypeNameHandling }")`}</div>);
                        } else {
                            formatStringFunctionDescriptions.push(<div key={index}>{formatStringfunction.FormatType}</div>);
                        }
                        index++;
                    }
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="formatString"><div className="p-2 border align-top text-left font-bold">Format String</div><div className="border p-2 break-words">{formatStringFunctionDescriptions}</div></div>);
                }
                if (mappingField.sourceTimeZoneId !== "") {
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="sourceTimeZoneId"><div className="p-2 border align-top text-left font-bold">Source TZ</div><div className="border p-2 break-words">{mappingField.sourceTimeZoneId}</div></div>);
                }
                if (mappingField.destinationTimeZoneId !== "") {
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="destinationTimeZoneId"><div className="p-2 border align-top text-left font-bold">Destination TZ</div><div className="border p-2 break-words">{mappingField.destinationTimeZoneId}</div></div>);
                }
            }
            if (mappingField.useDataMaps) {
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="useDataMaps"><div className="p-2 border align-top text-left font-bold">Use Data Map</div><div className="border p-2 break-words">{mappingField.dataMapName}</div></div>);
            }
            if (mappingField.urlSubstitution) {
                mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="urlSubstitution"><div className="p-2 border align-top text-left font-bold">Substitue URLs</div><div className="border p-2 break-words">TTL: {mappingField.urlSubstitutionTTL}, Key Length: {mappingField.urlSubstitutionKeyLength}</div></div>);
            }
            if (mappingField.applyStringConcatenations) {
                let concatenationIndex = 0;
                for (const concatenation of mappingField.concatenations) {
                    let concatenationString;
                    if (concatenation.source === "Constant") {
                        concatenationString = `Constant "${ concatenation.value }"`;
                    } else {
                        concatenationString = concatenation.source;
                    }
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key={`applyStringConcatenations-${ concatenationIndex }`}><div className="p-2 border align-top text-left font-bold">Concatenate</div><div className="border p-2 break-words">{concatenationString}</div></div>);
                    concatenationIndex++;
                }
            }
            if (mappingField.applyCalculation) {
                let calculationIndex = 0;
                for (const calculation of mappingField.calculations) {
                    let calculationString;
                    if (calculation.mathOperation !== "") {
                        calculationString = `${ MathOperations[calculation.mathOperation].ReviewPageDescription  } `;
                        calculationString += calculation.source === "Constant" ? calculation.mathOperand : calculation.source;
                    }
                    if (calculation.roundOperation !== "") {
                        if (calculation.mathOperation === "") {
                            // Add Zero
                            calculationString += `, ${ MathOperations["Add"].ReviewPageDescription } ${ 0 }`;
                        }
                        calculationString += ` and then ${ calculation.roundOperation }`;
                    }
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key={`applyCalculation-${  calculationIndex }`}><div className="p-2 border align-top text-left font-bold">Apply Calculation</div><div className="border p-2 break-words">{calculationString}</div></div>);
                    calculationIndex++;
                }
            }
            mappingFieldDisplays.push(<div className="p-2" key={`mapping-field-${  counter }`}><div className="font-bold">Column {counter}</div><div className="grid gap-0 provisionable-item-review-page-custom-sub-grid" key={`${ props.field.Parameter.Identifier  }-${  counter }`}>{mappingFieldInfoRows}</div></div>);
            counter++;
        }

        if (Object.keys(props.formState[props.field.Parameter.Identifier].value.arrayData).length > 0) {
            const arrayDataRows = [];
            for (const arrayIdentifier of Object.keys(props.formState[props.field.Parameter.Identifier].value.arrayData).sort()) {
                const arrayInfoText = props.formState[props.field.Parameter.Identifier].value.arrayData[arrayIdentifier].VerticalOrHorizontal === "vertical" ? "Vertical" : `Horizontal, ${  props.formState[props.field.Parameter.Identifier].value.arrayData[arrayIdentifier].NumberOfColumns  } Columns`;
                arrayDataRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key={`arrayData${  arrayIdentifier }`}><div className="p-2 border align-top text-left font-bold">{arrayIdentifier}</div><div className="border p-2 break-words">{arrayInfoText}</div></div>);
            }
            mappingFieldDisplays.push(<div className="p-2 bg-white" key="mapping-array-info"><div className="font-bold">Array Info</div><div className="grid gap-0 provisionable-item-review-page-custom-sub-grid" key={`${ props.field.Parameter.Identifier  }arrayInfo`}>{arrayDataRows}</div></div>);
        }
        fieldValue = mappingFieldDisplays;
    } else if (fieldType === "FileSelectorField") {
        const fileNames = [];
        for (const file of props.formState[props.field.Parameter.Identifier].value) {
            fileNames.push(file.name);
        }
        fieldValue = fileNames.join(", ");
    } else if (["Import.DataSelector", "DataSelector"].includes(fieldType)) {
        let sourceFileFieldNames = [];
        if (props.formState[props.field.FileLayoutSelectorProvisioningIdentifier].externalData !== null && props.formState[props.field.FileLayoutSelectorProvisioningIdentifier].externalData.length > 0) {
            const flattenedData = flattenConvertJsonData(null, props.formState[props.field.FileLayoutSelectorProvisioningIdentifier].externalData[0]);
            sourceFileFieldNames = Object.keys(flattenedData);
        }

        let counter = 1;
        const mappingFieldDisplays = [];
        const propertiesToDisplay = [...props.formState[props.field.Parameter.Identifier].value];
        propertiesToDisplay.sort((a, b) => {
            if (a.IsRequired && !b.IsRequired) {
                return -1;
            }
            if (b.IsRequired && !a.IsRequired) {
                return 1;
            }
            if (a.displayIndex < b.displayIndex) {
                return -1;
            }
            if (a.displayIndex > b.displayIndex) {
                return 1;
            }
            // stackoverflow gem for doing alphabetical sorting.
            return a.Name.localeCompare(b.Name);
        });
        for (const property of propertiesToDisplay) {
            if (property.displayIndex !== -1 && Array.isArray(property.userEnteredData) && property.userEnteredData.length > 0) {
                // this will result in IsRequired alphabetically and then DisplayIndex alphabetically.
                for (const userEnteredData of property.userEnteredData) {
                    const mappingFieldInfoRows = [];
                    const sourceFieldName = sourceFileFieldNames[parseInt(userEnteredData.dataFileLocation)];
                    let sourceString = "";
                    if (userEnteredData.source === "DataFile") {
                        if (sourceFileFieldNames.length > parseInt(userEnteredData.dataFileLocation)) {
                            if (userEnteredData.lookupEntityKeyByIdentifier === true) {
                                sourceString = `Lookup Entity Key by: ${ sourceFieldName }`;
                            } else {
                                sourceString = `Data file column: ${ sourceFieldName }`;    
                            }
                        }
                    } else if (userEnteredData.source === "Constant") {
                        let displayValue = userEnteredData.constantValue;
                        if (Array.isArray(property.ValidConstantValuesOnImport)) {
                            for (const possibleValue of property.ValidConstantValuesOnImport) {
                                if (possibleValue.Value === userEnteredData.constantValue) {
                                    displayValue = possibleValue.Key;
                                }
                            }
                        }
                        sourceString = `Constant value: ${ displayValue }`;
                    } else if (userEnteredData.source === "RegionDefault") {
                        sourceString = "Region default";
                    }
                    mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="source"><div className="p-2 border align-top text-left font-bold">Source</div><div className="border p-2 break-words">{sourceString}</div></div>);

                    if (userEnteredData.lookupEntityKeyByIdentifier === true && userEnteredData.lookupCacheFilename !== "") {
                        mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="lookupCacheFilename"><div className="p-2 border align-top text-left font-bold">Cache File</div><div className="border p-2 break-words">{userEnteredData.lookupCacheFilename}</div></div>);
                    }
                    if (userEnteredData.formatOption !== "None") {
                        mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="dataType"><div className="p-2 border align-top text-left font-bold">Data Type</div><div className="border p-2 break-words">{userEnteredData.formatTypeCode}</div></div>);
                        if (userEnteredData.parseString !== "") {
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="parseString"><div className="p-2 border align-top text-left font-bold">Parse String</div><div className="border p-2 break-words">{userEnteredData.parseString}</div></div>);
                        }
                        if (userEnteredData.formatString !== "") {
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="formatString"><div className="p-2 border align-top text-left font-bold">Format String</div><div className="border p-2 break-words">{userEnteredData.formatString}</div></div>);
                        }
                        if (Array.isArray(userEnteredData.formatStringFunctions) && userEnteredData.formatStringFunctions.length > 0) {
                            const formatStringFunctionDescriptions = [];
                            let index = 0;
                            for (const formatStringfunction of userEnteredData.formatStringFunctions) {
                                if (formatStringfunction.FormatType === "Replace") {
                                    formatStringFunctionDescriptions.push(<div key={index}>{`Replace(OldValue = "${ formatStringfunction.OldValue }", NewValue = "${ formatStringfunction.NewValue }")`}</div>);
                                } else if (["PadLeft", "PadRight"].includes(formatStringfunction.FormatType)) {
                                    formatStringFunctionDescriptions.push(<div key={index}>{`${ formatStringfunction.FormatType }(PaddingChar = "${ formatStringfunction.PaddingChar }", TotalWidth = ${ formatStringfunction.TotalWidth })`}</div>);
                                } else if (["SplitLeft", "SplitRight"].includes(formatStringfunction.FormatType)) {
                                    formatStringFunctionDescriptions.push(<div key={index}>{`${ formatStringfunction.FormatType }(SplitOn = "${ formatStringfunction.SplitOn }")`}</div>);
                                } else if (formatStringfunction.FormatType === "Insert") {
                                    formatStringFunctionDescriptions.push(<div key={index}>{`Insert(StartIndex = ${ formatStringfunction.StartIndex }, Value = ${ formatStringfunction.StartIndex })`}</div>);
                                } else if (formatStringfunction.FormatType === "Substring") {
                                    if (formatStringfunction.Length !== "") {
                                        formatStringFunctionDescriptions.push(<div key={index}>{`Substring(StartIndex = ${ formatStringfunction.StartIndex }, Length = ${ formatStringfunction.Length })`}</div>);
                                    } else {
                                        formatStringFunctionDescriptions.push(<div key={index}>{`Substring(StartIndex = ${ formatStringfunction.StartIndex })`}</div>);
                                    }
                                } else if (formatStringfunction.FormatType === "StripLineBreaks") {
                                    formatStringFunctionDescriptions.push(<div key={index}>{`StripLineBreaks(ReplacementValue = "${ formatStringfunction.ReplacementValue }")`}</div>);
                                } else if (formatStringfunction.FormatType === "JsonSerialize") {
                                    formatStringFunctionDescriptions.push(<div key={index}>{`JsonSerialize(IgnoreNullAndDefaultValues = ${ formatStringfunction.IgnoreNullAndDefaultValues }, TypeNameHandling = "${ formatStringfunction.TypeNameHandling }")`}</div>);        
                                } else {
                                    formatStringFunctionDescriptions.push(<div key={index}>{formatStringfunction.FormatType}</div>);
                                }
                                index++;
                            }
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="formatString"><div className="p-2 border align-top text-left font-bold">Format String</div><div className="border p-2 break-words">{formatStringFunctionDescriptions}</div></div>);
                        }
                        if (userEnteredData.sourceTimeZoneId !== "") {
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="sourceTimeZoneId"><div className="p-2 border align-top text-left font-bold">Source TZ</div><div className="border p-2 break-words">{userEnteredData.sourceTimeZoneId}</div></div>);
                        }
                        if (userEnteredData.destinationTimeZoneId !== "") {
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="destinationTimeZoneId"><div className="p-2 border align-top text-left font-bold">Destination TZ</div><div className="border p-2 break-words">{userEnteredData.destinationTimeZoneId}</div></div>);
                        }
                    }
                    if (userEnteredData.useDataMaps) {
                        mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="useDataMap"><div className="p-2 border align-top text-left font-bold">Data Map</div><div className="border p-2 break-words">{userEnteredData.dataMapName}</div></div>);
                    }
                    if (userEnteredData.urlSubstitution) {
                        mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="urlSubstitution"><div className="p-2 border align-top text-left font-bold">Substitue URLs</div><div className="border p-2 break-words">TTL: {userEnteredData.urlSubstitutionTTL}, Key Length: {userEnteredData.urlSubstitutionKeyLength}</div></div>);
                    }
                    if (userEnteredData.applyStringConcatenations) {
                        let concatenationIndex = 0;
                        for (const concatenation of userEnteredData.concatenations) {
                            let concatenationString;
                            if (concatenation.source === "Constant") {
                                concatenationString = `Constant "${ concatenation.value }"`;
                            } else {
                                concatenationString = `Data File ${ concatenation.source }`;
                            }
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key={`applyStringConcatenations-${ concatenationIndex }`}><div className="p-2 border align-top text-left font-bold">Concatenate</div><div className="border p-2 break-words">{concatenationString}</div></div>);
                            concatenationIndex++;
                        }
                    }
                    if (userEnteredData.applyCalculation) {
                        let calculationIndex = 0;
                        for (const calculation of userEnteredData.calculations) {
                            let calculationString;
                            if (calculation.mathOperation !== "") {
                                calculationString = `${ MathOperations[calculation.mathOperation].ReviewPageDescription  } `;
                                calculationString += calculation.source === "Constant" ? calculation.mathOperand : calculation.source;
                            }
                            if (calculation.roundOperation !== "") {
                                if (calculation.mathOperation === "") {
                                    // Add Zero
                                    calculationString += `, ${  MathOperations["Add"].ReviewPageDescription  } ${  0 }`;
                                }
                                calculationString += ` and then ${ calculation.roundOperation }`;
                            }
                            mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key={`applyCalculation-${  calculationIndex }`}><div className="p-2 border align-top text-left font-bold">Apply Calculation</div><div className="border p-2 break-words">{calculationString}</div></div>);
                            calculationIndex++;
                        }
                    }
                    if ((sourceFieldName.split("[]").length > property.Name.split("[]").length) || (!property.Name.endsWith("[]") && sourceFieldName.endsWith("[]"))) {
                        mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="concatenateDelimiter"><div className="p-2 border align-top text-left font-bold">Concatenate</div><div className="border p-2 break-words">{`Concatenate using '${  userEnteredData.concatenateDelimiter  }' delimiter`}</div></div>);
                    }
                    if (userEnteredData.useLookupEntityKeyFallback) {
                        mappingFieldInfoRows.push(<div className="contents provisionable-item-review-page-custom-sub-grid-row" key="useLookupEntityKeyFallback"><div className="p-2 border align-top text-left font-bold">EK Fallback</div><div className="border p-2 break-words">{userEnteredData.lookupEntityKeyFallback}</div></div>);
                    }
                    let destinationString = property.Name;
                    if (property.Name.endsWith("CustomProperties")) {
                        destinationString = `${ property.Name  }: ${  userEnteredData.customPropertyKey }`;
                    }
                    mappingFieldDisplays.push(<div className="p-2" key={`mapping-field-${  counter }`}><div className="font-bold">{destinationString}</div><div className="grid gap-0 provisionable-item-review-page-custom-sub-grid" key={`${ props.field.Parameter.Identifier  }-${  counter }`}>{mappingFieldInfoRows}</div></div>);
                    counter++;
                }
            }
        }
        fieldValue = mappingFieldDisplays;
    } else if (fieldType === "FixedWidthColumnsSelector") {
        const columns = [];
        for (const columnDefinition of props.formState[props.field.Parameter.Identifier].value) {
            columns.push(<tr key={columnDefinition.StartIndex}>
                <td className="px-2">{columnDefinition.StartIndex}</td>
                <td className="px-2">{columnDefinition.Length}</td>
                <td className="px-2">{columnDefinition.Justification}</td>
            </tr>);
        }
        fieldValue = <table>
            <thead>
                <tr>
                    <th className="px-2">Start Index</th>
                    <th className="px-2">Length</th>
                    <th className="px-2">Justification</th>
                </tr>
                {columns}
            </thead>
        </table>;
    }
    return (
        <div className="contents provisionable-item-review-page-custom-grid-row" key={props.field.Parameter.Identifier}>
            {
                ["Export.DataSelector", "Import.DataSelector", "DataSelector"].includes(fieldType)
                    ?   <div className="p-2 border col-span-2">
                            <div className="font-bold">{props.field.Parameter.Name}</div>
                            <div>
                                {
                                    error
                                        ? <div className="text-orange-500 font-bold"><FontAwesomeIcon icon={faWarning} /> {error.data.Message || error.message}</div>
                                        : isFetching
                                            ? <span className="text-orange-500"><FontAwesomeIcon icon={faSpinner} className="spinner" /> Loading external data...</span>
                                            : fieldValue
                                }
                            </div>
                        </div>
                    :   <>
                            <div className="p-2 border font-bold align-top text-left">{props.field.Parameter.Name}</div>
                            <div className="p-2 border">
                                {
                                    error
                                        ? <div className="text-orange-500 font-bold"><FontAwesomeIcon icon={faWarning} /> {error.data.Message || error.message}</div>
                                        : isFetching
                                            ? <span className="text-orange-500"><FontAwesomeIcon icon={faSpinner} className="spinner" /> Loading external data...</span>
                                            : fieldValue
                                }
                            </div>
                        </>
            }
        </div>
    );
}